<template>
    <div>
        <Breadcrumbs main="" title="users.header"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="col-sm-12 p-0">
              <div class="card mt-4 p-4">
                <form class="theme-form">
                  <div class="form-row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="name" class="col-form-label text-primary f-w-600" style="font-size: 18px">{{ $t('users.name') }}</label>
                        <span style="font-size: 18px"> {{ currentName }}</span>
                        <input class="form-control" v-model="newName" type="text" :placeholder="$t('users.inputNewName')" style="font-size: 18px">
                        <button @click="verifyNameData" class="btn btn-primary btn-lg active" type="button"><i class="fa fa-link"></i> {{ $t("users.update") }}</button>                                                
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="phone" class="col-form-label text-primary f-w-600" style="font-size: 18px">{{$t("users.phone")}}</label>
                        <span style="font-size: 18px"> {{ currentPhone }}</span>
                        <input class="form-control" v-model="newPhone" type="number" :placeholder="$t('users.inputNewPhone') " style="font-size: 18px">
                        <button @click="verifyPhoneData" class="btn btn-primary btn-lg active" type="button"><i class="fa fa-link"></i> {{ $t("users.update") }}</button>
                      </div>
                    </div>                      
                  </div>
                  <div class="form-row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="password" class="col-form-label text-primary f-w-600" style="font-size: 18px;" >{{ $t("users.password") }}</label>
                        <span style="font-size: 18px"> ********</span>
                        <input class="form-control" v-model="newPassword" type="password" :placeholder="$t('users.inputNewPassword')" style="font-size: 18px">
                        <button @click="verifyPasswordData" class="btn btn-primary btn-lg active" type="button"><i class="fa fa-link"></i> {{ $t("users.update") }}</button>
                      </div>    
                    </div>                      
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="deleteAccount"  class="col-form-label text-danger f-w-600" style="font-size: 18px">{{ $t('users.deleteAccount') }}</label><br>
                        <span style="font-size: 18px"> {{ $t('users.deleteAccountMsg') }}</span><br>
                        <button @click="actDeleteAccount" class="btn btn-danger btn-lg active" type="button"><i class="fa fa-remove"></i> {{ $t("users.delete") }}</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
import { userInfoByUserId , deleteAccount, changePassword , changeName , changePhone} from "../../firebase"
export default {
  data(){
    return {
      userId : "",
      currentName: '',
      currentPhone: '',
      newName: '',
      newPhone: '',
      newPassword: ''
    }
  },
  mounted(){
    this.userId= localStorage.getItem("userId")
    userInfoByUserId(this.userId , (res)=>{
      this.currentName = res.name
      this.currentPhone = res.phone
    })
  },
  methods: {
    verifyNameData(){
      let msg = 'completed'
        if (this.newName === ''){
          msg = 'users.enterNameMsg'
        }  
        if (msg === 'completed'){
          this.actNameUpdate()                    
        }else{
            this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position:'top-center', duration: 5000});     
        }
    },
    verifyPhoneData(){
      let msg = 'completed'
        if (this.newPhone ===''){
            msg = 'users.enterPhoneMsg'
        } else if (isNaN(this.newPhone)){
            msg = 'users.numberFormatMsg'
        }  
        if (msg === 'completed'){
          this.actPhoneUpdate()                    
        }else{
            this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'top-center', duration: 5000});     
        }
    },     
    verifyPasswordData(){
      let msg = 'completed'
        if (this.newPassword ===''){
            msg = 'loginPage.enterPwdMsg'
        } else if (this.newPassword.length < 8){
            msg = 'loginPage.pwd8UpMsg'
        }  
        if (msg === 'completed'){
          this.actPasswordUpdate()             
        }else{
            this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'top-center', duration: 5000});     
        }
    },
    actDeleteAccount() {
      this.$bvModal.msgBoxConfirm(this.$t('users.confirmDeleteMsg'), {
        title: this.$t('matchTx.plsConfirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('users.delete'),
        cancelTitle: this.$t('matchTx.no'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async (value) => {
        if (value) {
          deleteAccount(this.userId, (res)=>{
            if (res === 'success') {
              this.$toasted.show( `${this.$t('users.successDeleteAccountMsg')}`, {theme: 'bubble', type: 'success' , position: 'top-center', duration: 5000});  
              this.logout()
            } else {
              this.$toasted.show( `${this.$t('users.failedDeleteAccountMsg')}`, {theme: 'bubble', type: 'error' , position: 'top-center', duration: 5000});  
            }
          })
        } else {
          return
        }
      })
      .catch(error => {
        this.$toasted.show( `Action-Update-Email : ${error}`, {theme: 'bubble', type: 'error' , position: 'top-center', duration: 5000});  
      })
    },
    actPasswordUpdate() {
      this.$bvModal.msgBoxConfirm(this.$t('users.confirmUpdateMsg'), {
        title: this.$t('matchTx.plsConfirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('matchTx.yes'),
        cancelTitle: this.$t('matchTx.no'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async (value) => {
        if (value) {
          changePassword(this.newPassword, (res)=>{
            if (res === 'success') {
              this.$toasted.show( `${this.$t('users.successChanging')}`, {theme: 'bubble', type: 'success' , position: 'top-center', duration: 5000});  
              this.refresh()
            } else {
              this.$toasted.show( `${this.$t('users.failedChanging')}`, {theme: 'bubble', type: 'error' , position: 'top-center', duration: 5000});  
            }
          })
        } else {
          return
        }
      })
      .catch(error => {
        this.$toasted.show( `Action-Update-Password : ${error}`, {theme: 'bubble', type: 'error' , position: 'top-center', duration: 5000});  
      })
    },
    actNameUpdate() {
      this.$bvModal.msgBoxConfirm(this.$t('users.confirmUpdateMsg'), {
        title: this.$t('matchTx.plsConfirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: this.$t('matchTx.yes'),
        cancelTitle: this.$t('matchTx.no'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async (value) => {
        if (value) {
          changeName(this.userId, this.newName, (res)=>{
            if (res === 'success') {
              this.$toasted.show( `${this.$t('users.successDataSaving')}`, {theme: 'bubble', type: 'success' , position: 'top-center', duration: 5000});  
              this.refresh()
            } else {
              this.$toasted.show( `${this.$t('users.failedDataSaving')}`, {theme: 'bubble', type: 'error' , position: 'top-center', duration: 5000});  
            }
          })
        } else {
          return
        }
      })
      .catch(error => {
        this.$toasted.show( `Action-Update-Name : ${error}`, {theme: 'bubble', type: 'error' , position: 'top-center', duration: 5000});  
      })
    },
    actPhoneUpdate() {
      this.$bvModal.msgBoxConfirm(this.$t('users.confirmUpdateMsg'), {
        title: this.$t('matchTx.plsConfirm'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: this.$t('matchTx.yes'),
        cancelTitle: this.$t('matchTx.no'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async (value) => {
        if (value) {
          changePhone(this.userId, this.newPhone, (res)=>{
            if (res === 'success') {
              this.$toasted.show( `${this.$t('users.successDataSaving')}`, {theme: 'bubble', type: 'success' , position: 'top-center', duration: 5000});  
              this.refresh()
            } else {
              this.$toasted.show( `${this.$t('users.failedDataSaving')}`, {theme: 'bubble', type: 'error' , position: 'top-center', duration: 5000});  
            }
          })
        } else {
          return
        }
      })
      .catch(error => {
        this.$toasted.show( `Action-Update-Phone : ${error}`, {theme: 'bubble', type: 'error' , position: 'top-center', duration: 5000});  
      })
    },
    refresh(){
      userInfoByUserId(this.userId , (res)=>{
      // console.log(`[userInfoByUserId => ${JSON.stringify(res , null , 2)}]`)
      this.currentEmail = res.email
      this.currentName = res.name
      this.currentPhone = res.phone
    })
    },
    logout() {
      localStorage.clear('user')      
      this.$router.push('/auth/login');
    },
  }
}
</script>