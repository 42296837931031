<template>
  <div>
    <Breadcrumbs main="matchTx.dashboard" title="shipApproval.header" />
    <b-container fluid>
      <!-- <h3 class="mb-1 mt-4 f-w-600">{{ $t('shipApproval.header') }}</h3> -->
    <div>
      <b-table
        sticky-header
        head-variant="light"
        table-variant="info"
        show-empty
        sort-icon-left
        stacked="md"      
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
      >
        <template #cell(contImage)="row" >
          <b-button  size="sm" @click="openModalImage(row.item, row.index, $event.target )" class="m-1">
              {{ row.item.contNo }}
          </b-button>
        </template>
        <template #cell(bookingIdFile)="row" >
            <b-button  size="sm" @click="openBkgFile(row.item, row.index, $event.target )" class="m-1">
                {{ row.item.bkgId }}
            </b-button>
        </template>
        <template #cell(actAgent)="row" >
          <b-button  v-show="row.item.actAgent.accept" size="sm" @click="actAgentAccept(row.item)" class="m-1">
            {{ $t('shipApproval.approve') }}
          </b-button>
          <b-button  v-show="row.item.actAgent.reject" size="sm" @click="actAgentReject(row.item)" class="m-1">
            {{ $t('shipApproval.reject') }}
          </b-button>
        </template>
        <template #empty>
            <h5>{{ $t('reuse.noRecord') }}</h5>
        </template>
      </b-table>
      <b-row>
        <div class="row col-sm-4 ">
          <b-col class="my-1 ml-3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              class="my-0"
            ></b-pagination>
          </b-col>   
          <b-col sm="3" md="6" class="my-1">
            <b-form-group
              :label= "$t('require.perPage')"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </div>
      </b-row>
      <!-- Info modal Container Image-->
      <b-modal id="contModal" :title="modalTitle" ok-only @hide="resetInfoModal">
        <div class="card me-5" style=" width:15rem">
            <img  class="card-img-top" :src="imageUriModal" alt="Image Preview"/>
        </div>
      </b-modal>
    </div>
  </b-container>
  </div>
</template>
<script>
import {compNameEngByCompId, matchTxsAgent,updateStatus, updateMatchTx2StepN, bkgFileUri, saveWithId } from "../../firebase"
  export default {
    data() {
      return {
        compId : '',
        compNameEng : '',
        items: [],
        fields: [
          { key: 'mTxId', label: this.$t('matchTx.mTxId'), sortable: true, },  
          { key: 'jobTypeReqr', label: this.$t('shipApproval.jobType'), sortable: true, 
              formatter: value => {
                return this.$t(`shipApproval.${value}`)
              }}, 
          { key: 'contImage', label: this.$t('container.contNo'), variant: 'info'},  
          // { key: 'contNo', label: this.$t('matchTx.contNo'), sortable: true, },          
          { key: 'detDate', label: this.$t('matchTx.detDate'), sortable: true, }, 
          { key: 'contType', label: this.$t('matchTx.contType'), sortable: true, },
          { key: 'contSize', label: this.$t('matchTx.contSize'), sortable: true, },
          { key: 'contGrade', label: this.$t('matchTx.contGrade'), sortable: true, },
          { key: 'maxGross', label: this.$t('container.maxGross'), sortable: true, },
          { key: 'tare', label: this.$t('container.tare'), sortable: true, },
          { key: 'agent', label: this.$t('matchTx.agent'), sortable: true, },
          { key: 'bookingIdFile', label: this.$t('matchTx.bkgId'), variant: 'info'},  
          // { key: 'bkgId', label: this.$t('matchTx.bkgId'), sortable: true, },         
          { key: 'compNameLocalRels', label: this.$t('matchTx.compNameLocalRels'), sortable: true, },                
          { key: 'contRelsAddress', label: this.$t('matchTx.contRelsAddress'), sortable: false, },
          { key: 'compNameLocalReqr', label: this.$t('matchTx.compNameLocalReqr'), sortable: true, },                
          { key: 'contReqrAddress', label: this.$t('matchTx.contReqrAddress'), sortable: false, },
               { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
                    formatter: value => {
                    return this.$moment(value).format("YY-MM-DD @ HH:mm")
                }},
          { key: 'msReqr', label: this.$t('matchTx.matchStatus'), sortable: false,variant: 'warning',
            formatter: value => {
              return this.$t(`matchTx.${value}`)
            }}, 
          { key: 'jbReqr', label: this.$t('matchTx.jobStatus'), sortable: true,variant: 'warning',
            formatter: value => {
              return this.$t(`matchTx.${value}`)
            }}, 
          { key: 'actAgent', label: this.$t('matchTx.actions'),variant: 'warning' }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        imageUriModal: '',
        modalTitle : ''
      }
    },
    computed: {
    },
    mounted() {
      this.compId= localStorage.getItem("compId")
      compNameEngByCompId(this.compId , (nameEng)=>{
         // = agent (controlled menu by compId under (userCat = agent) when login/register)
        this.compNameEng = nameEng
        console.log(`compNameEng => ${nameEng}`)
        matchTxsAgent(nameEng, (res)=>{
        this.items = res
        this.totalRows = res.length
      })
      }) 
    },
    methods: {
      async refreshMatchTx() {
        await matchTxsAgent(this.compNameEng , (res)=>{
          this.items = res
          this.totalRows = res.length
        })
      },
      actAgentReject(item) {
        this.$bvModal.msgBoxConfirm(this.$t('matchTx.confirmRejectMsg'), {
          title: this.$t('matchTx.plsConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: this.$t('matchTx.yes'),
          cancelTitle: this.$t('matchTx.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async(value) => {
            if (value) {
              item.txSearcher == "Release" ? await updateStatus('Require', item.reqrId, 'Require') : await updateStatus('Release', item.relsId, 'Release')// return  status of Poster for recycle.
              //updateMatchTx2StepN_ReqrSchr( 'MatchTx',item.mTxId , 2.1)
              await updateMatchTx2StepN( 'MatchTx',item.mTxId , 2.1).then(async()=>{
                await this.saveShipReview(item , false)
                await this.refreshMatchTx()
              })
            } else {
              return
            }
          })
          .catch(error => {
            this.$toasted.show( `Action-Reject : ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
          })
      },      
      actAgentAccept(item) {
        this.$bvModal.msgBoxConfirm(this.$t('matchTx.confirmAcceptMsg'), {
          title: this.$t('matchTx.plsConfirm'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: this.$t('matchTx.yes'),
          cancelTitle: this.$t('matchTx.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(async(value) => {
            if (value) {
              if (item.jobTypeReqr === 'Transfer') {
                item.sealNo ? await updateMatchTx2StepN('MatchTx', item.mTxId, 4 , item.sealNo) :  await updateMatchTx2StepN('MatchTx',item.mTxId , 3)
              } else {
                //updateMatchTx2StepN_ReqrSchr('MatchTx',item.mTxId , 3)
                item.txSearcher == 'Reuse' ? await updateMatchTx2StepN('MatchTx', item.mTxId, 4 , item.sealNo) :  await updateMatchTx2StepN('MatchTx',item.mTxId , 3)
              }
              await this.saveShipReview(item , true)
              await this.refreshMatchTx()
            } else {
              return
            }
          })
          .catch(error => {
            this.$toasted.show( `Action-Accept: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
          })
      },
      openModalImage(item, index, button) {
            this.imageUriModal = item.imageUri
            this.modalTitle = item.contNo
            this.$root.$emit('bv::show::modal', "contModal" , button)
        },
        resetInfoModal() {
            this.modalTitle = ''
            this.imageUriModal = ''
        },
       async openBkgFile(item, index, button) {   
          console.log(`bkgFile, bkgId => ${JSON.stringify(item.bkgId)}`)
            let fileUri = await bkgFileUri(item.bkgId , item.compIdReqr)        
            console.log(`bkgFile, fileUri => ${JSON.stringify(fileUri)}`)
            // window.location.href= fileUri
            window.open(fileUri)
        },
        async saveShipReview( item , isApprove){
            const data = {
                mTxId : item.mTxId,
                isApprove: isApprove,  
                agent: item.agent,                                
                bkgId: item.bkgId,                
                contNo : item.contNo,
                imageUri : item.imageUri,                
                contType: item.contType,
                contSize: item.contSize,
                contGrade: item.contGrade,                               
                detDate : item.detDate,
                detTS : item.detTS,
                maxGross : item.maxGross,
                tare : item.tare,
                jobMethCat : item.txSearcher,                
                compIdReqr : item.compIdReqr,                
                compNameLocalReqr :item.compNameLocalReqr,   
                distance: item.distance,                
                compIdRels:item.compIdRels,                               
                compNameLocalRels:item.compNameLocalRels,                
                timestamp: Date.parse(new Date()),                                       
            }
            await saveWithId('ShipReport', item.mTxId, data)
        },
    }
  }
</script>