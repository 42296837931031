<template>
	<div>
		<Breadcrumbs main="" title="release.header"/>
		<!-- Container-fluid starts-->
		<div class="container-fluid search-page">
            <div>
                <h4 class="mb-1 mt-4 f-w-600">{{ $t('release.postedRelease') }} - Admin</h4>
                <b-table
                    sticky-header
                    head-variant="light"
                    table-variant="success"
                    show-empty
                    sort-icon-left
                    stacked="md"      
                    :items="itemsPostedRels"
                    :fields="fieldsPostedRels"
                    :current-page="currentPagePostedRels"
                    :per-page="perPagePostedRels"
                >
                    <template #cell(actPostedRels)="row" >
                        <b-button  size="sm" @click="actPostedRelsCancel(row.item)" class="m-1">
                            {{ $t('matchTx.cancel') }}
                        </b-button>
                    </template>
                    <template #empty>
                        <h5>{{ $t('reuse.noRecord') }}</h5>
                    </template>
                </b-table>
                <b-row>
                    <div class="row col-sm-4 ">
                    <b-col class="my-1 ml-3">
                        <b-pagination
                        v-model="currentPagePostedRels"
                        :total-rows="totalRowsPostedRels"
                        :per-page="perPagePostedRels"
                        align="fill"
                        class="my-0"
                        ></b-pagination>
                    </b-col>   
                    <b-col sm="3" md="6" class="my-1">
                        <b-form-group
                        :label= "$t('require.perPage')"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPagePostedRels"
                            :options="pageOptionsPostedRels"
                        ></b-form-select>
                        </b-form-group>
                    </b-col>
                    </div>
                </b-row>
                <!-- Info modal Reqr Attch Seal-->
            </div>

        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>
<script>
import { postedByAdmin, getStatusByRelsId, updateContActivebyContNo,  updateStatus ,} from "../../firebase"

export default {
	data(){
		return{
            itemsPostedRels: [],
            fieldsPostedRels: [
                { key: 'relsId', label: this.$t('release.relsId'), sortable: true, },  
                { key: 'contNo', label: this.$t('release.contNo'), sortable: true, },                        
                { key: 'agent', label: this.$t('release.agent'), sortable: true, }, 
                { key: 'compNameLocalRels', label: this.$t('matchTx.compNameLocalRels'), sortable: true, },                
                { key: 'contRelsAddress', label: this.$t('matchTx.contRelsAddress'), sortable: false, },   
                { key: 'distLim', label: this.$t('require.distLim'), sortable: true, },             
                { key: 'detDate', label: this.$t('require.detDate'), sortable: true, }, 
                { key: 'contType', label: this.$t('release.contType'), sortable: true, },
                { key: 'contSize', label: this.$t('release.contSize'), sortable: true, },
                { key: 'contGrade', label: this.$t('release.contGrade'), sortable: true, },
                { key: 'jobType', label: this.$t('release.jobType'), sortable: true, 
                    formatter: value => {
                        return this.$t(`require.${value}`)
                }},
                { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
                    formatter: value => {
                        return this.$moment(value).format("YY-MM-DD @ HH:mm")
                }},
                { key: 'actPostedRels', label: this.$t('matchTx.actions'), variant: 'danger'}
            ],
            totalRowsPostedRels: 1,
            currentPagePostedRels: 1,
            perPagePostedRels: 10,
            pageOptionsPostedRels: [5, 10, 15, { value: 100, text: "Show a lot" }],
		}
	},
	mounted(){
        postedByAdmin( "Release", "Release" , (res)=>{
            this.itemsPostedRels = res
            this.totalRowsPostedRels = res.length
        })  
	},
	methods:{
        async refreshPostedRelease() {
            await postedByAdmin( "Release", "Release" , (res)=>{
                this.itemsPostedRels = res
                this.totalRowsPostedRels = res.length
                })
        },
        async actPostedRelsCancel(item) {
            this.$bvModal.msgBoxConfirm(this.$t('require.confirmCancelMsg'), {
            title: this.$t('matchTx.plsConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: this.$t('matchTx.yes'),
            cancelTitle: this.$t('matchTx.no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
            .then(async(value) => {
                if (value) {
                    await getStatusByRelsId( item.relsId, async(res)=>{
                        if (res == "Matched") {
                            this.$toasted.show( this.$t("require.postedMatched"), {theme: 'bubble', type: 'info' , position: 'bottom-center', duration: 10000});  
                        } else {
                            await updateContActivebyContNo(item.contNo, true).then(()=>{
                                updateStatus('Release', item.relsId, 'Cancel')
                                this.$toasted.show( this.$t('require.postedCancelled'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
                                this.refreshPostedRelease()
                                window.location.reload()
                            })
                        }
                    })
                } else {
                    return
                }
            })
            .catch(error => {
                this.$toasted.show( `Action-Cancel: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 10000});  
            })
        },    
    }
}
</script>

