<template>
    <div>
        <Breadcrumbs main="manual.info" title="fees.processFees"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header"> 
                    <h2>{{ $t('fees.processFees') }}</h2>
                  </div>
                  <div class="card-body row pricing-card-design-2 pricing-content">
                    <div class="col-md-3">
                      <div class="pricing-block card text-center">
                        <svg x="0" y="0" viewBox="0 0 360 220">
                          <g>
                            <path fill="#4466f2" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48                                        L0.732,193.75z"></path>
                          </g>
                          <text transform="matrix(1 0 0 1 69.7256 116.2686)" fill="#fff" font-size="35">{{ $t('fees.reuseFees') }}</text>
                          <!-- <text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff" font-size="29.0829">.99</text> -->
                          <text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff" font-size="20">{{ $t('fees.perTx') }}</text>
                        </svg>
                        <div class="pricing-inner">
                          <h3 class="font-primary">{{ $t('fees.reuse') }}</h3>
                          <!-- <ul class="pricing-inner">
                            <li>
                              <h6><b>50GB</b> Disk Space</h6>
                            </li>
                            <li>
                              <h6><b>50</b> Email Accounts</h6>
                            </li>
                            <li>
                              <h6><b>50GB</b> Bandwidth</h6>
                            </li>
                            <li>
                              <h6><b>10</b> Subdomains</h6>
                            </li>
                            <li>
                              <h6><b>15</b> Domains</h6>
                            </li>
                          </ul>
                          <button class="btn btn-primary btn-lg" type="button" data-original-title="btn btn-primary btn-lg" title="">Subscribe</button> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="pricing-block card text-center">
                        <svg x="0" y="0" viewBox="0 0 360 220">
                          <g>
                            <path fill="#4466f2" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48                                        L0.732,193.75z"></path>
                          </g>
                          <text transform="matrix(1 0 0 1 69.7256 116.2686)" fill="#fff" font-size="35">{{ $t('fees.requireFees') }}</text>
                          <!-- <text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff" font-size="29.0829">.99</text> -->
                          <text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff" font-size="20">{{ $t('fees.perTx') }}</text>
                        </svg>
                        <div class="pricing-inner">
                          <h3 class="font-primary">{{ $t('fees.require') }}</h3>
                          <!-- <ul class="pricing-inner">
                            <li>
                              <h6><b>60GB</b> Disk Space</h6>
                            </li>
                            <li>
                              <h6><b>60</b> Email Accounts</h6>
                            </li>
                            <li>
                              <h6><b>60GB</b> Bandwidth</h6>
                            </li>
                            <li>
                              <h6><b>15</b> Subdomains</h6>
                            </li>
                            <li>
                              <h6><b>20</b> Domains</h6>
                            </li>
                          </ul>
                          <button class="btn btn-primary btn-lg" type="button" data-original-title="btn btn-primary btn-lg" title="">Subscribe</button> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="pricing-block card text-center">
                        <svg x="0" y="0" viewBox="0 0 360 220">
                          <g>
                            <path fill="#4466f2" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48                                        L0.732,193.75z"></path>
                          </g>
                          <text transform="matrix(1 0 0 1 69.7256 116.2686)" fill="#fff" font-size="35">{{ $t('fees.releaseFees') }}</text>
                          <!-- <text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff" font-size="29.0829">.99</text> -->
                          <text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff" font-size="20">{{ $t('fees.perTx') }}</text>
                        </svg>
                        <div class="pricing-inner">
                          <h3 class="font-primary">{{ $t('fees.release') }}</h3>
                          <!-- <ul class="pricing-inner">
                            <li>
                              <h6><b>60GB</b> Disk Space</h6>
                            </li>
                            <li>
                              <h6><b>60</b> Email Accounts</h6>
                            </li>
                            <li>
                              <h6><b>60GB</b> Bandwidth</h6>
                            </li>
                            <li>
                              <h6><b>15</b> Subdomains</h6>
                            </li>
                            <li>
                              <h6><b>20</b> Domains</h6>
                            </li>
                          </ul>
                          <button class="btn btn-primary btn-lg" type="button" data-original-title="btn btn-primary btn-lg" title="">Subscribe</button> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="pricing-block card text-center">
                        <svg x="0" y="0" viewBox="0 0 360 220">
                          <g>
                            <path fill="#4466f2" d="M0.732,193.75c0,0,29.706,28.572,43.736-4.512c12.976-30.599,37.005-27.589,44.983-7.061                                        c8.09,20.815,22.83,41.034,48.324,27.781c21.875-11.372,46.499,4.066,49.155,5.591c6.242,3.586,28.729,7.626,38.246-14.243                                        s27.202-37.185,46.917-8.488c19.715,28.693,38.687,13.116,46.502,4.832c7.817-8.282,27.386-15.906,41.405,6.294V0H0.48                                        L0.732,193.75z"></path>
                          </g>
                          <text transform="matrix(1 0 0 1 69.7256 116.2686)" fill="#fff" font-size="35">{{ $t('fees.transferFees') }}</text>
                          <!-- <text transform="matrix(0.9566 0 0 1 197.3096 83.9121)" fill="#fff" font-size="29.0829">.99</text> -->
                          <text transform="matrix(1 0 0 1 233.9629 115.5303)" fill="#fff" font-size="20">{{ $t('fees.perTx') }}</text>
                        </svg>
                        <div class="pricing-inner">
                          <h3 class="font-primary">{{ $t('fees.transfer') }}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div >
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header"> 
                    <h2>{{ $t('fees.serviceChargeCond') }}</h2>
                  </div>
                  <ul >
                    <li class=" disc bg-light text-info ml-4 mt-2" style="font-size: 20px;">{{ $t('fees.serviceFees') }}</li>
                    <li class="bg-light text-info ml-4 mt-2" style="font-size: 20px;">{{ $t('fees.sumMonthly') }}</li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>

<script>
export default {
}
</script>