<template>
	<div>
		<Breadcrumbs main="seal.resource" title="seal.header"/>
		<!-- Container-fluid starts-->
		<div class="container-fluid search-page">
			<div class="row">
                <div class="col-sm-6">
                    <form class="theme-form">
                        <div class="row ml-1 ">
                            <div class="form-group ">
                                <label for="sealNoStart" class="col-form-label pt-0">{{ $t("seal.sealNoStart") }}</label>                                                 
                                <input  v-model="sealNoStart" class="form-control" type="text" id="sealNoStart" :placeholder="$t('seal.sealNoStartPh')" >
                            </div>  
                            <div class="form-group ml-3">
                                <label for="sealNoEnd" class="col-form-label pt-0">{{ $t("seal.sealNoEnd") }}</label>                                                 
                                <input  v-model="sealNoEnd" class="form-control" type="text" id="sealNoEnd" :placeholder="$t('seal.sealNoEndPh')" >
                            </div>                              
                        </div>
                        <div class="form-group">
                            <label for="agent" class="col-form-label pt-0 f-w-600">{{ $t("bookingId.agent") }}</label>    
                            <!-- <select id="agent" class="form-select" v-model="agent">
                                <option v-for = "( agent, index) in agentList" :key="index" :value="agent.agentNameEng"> 
                                    <span ml-5> {{ agent.agentNameEng }}</span>
                                </option> 
                            </select> -->
                            <v-select label="agent"  style="font-size:16px;" v-model="agent" :options="agentList" :placeholder="$t('container.ownerPh')">                                        
                            </v-select>
                        </div>                     
                        <div class="form-group">
                            <button class="btn btn-primary btn-block" type="button" @click="handleSave">{{ $t('bookingId.save') }}</button>
                        </div>
                    </form>
                </div>
            </div>
            <div>
                <h4 class="mb-1 mt-4 f-w-600">{{ $t('seal.sealList') }}</h4>
                <b-table
                    sticky-header
                    head-variant="light"
                    table-variant="success"
                    show-empty
                    sort-icon-left
                    sort-direction="desc"
                    stacked="sm"      
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                >
                    <template #cell(act)="row" >
                        <b-button  size="sm" @click="actDelete(row.item)" class="m-1">
                            {{ $t('container.delete') }}
                        </b-button>
                    </template>
                </b-table>
                <b-row>
                    <div class="row col-sm-4 ">
                    <b-col class="my-1 ml-3">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        class="my-0"
                        ></b-pagination>
                    </b-col>   
                    <b-col sm="3" md="6" class="my-1">
                        <b-form-group
                        :label= "$t('require.perPage')"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                        ></b-form-select>
                        </b-form-group>
                    </b-col>
                    </div>
                </b-row>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>
<script>
import { agentList, sealNoListAll ,deleteItem, saveWithId} from "../../firebase"
export default {
	data(){
		return{
            agentList : [],
            sealNoStart : '',
            sealNoEnd : '',
            agent: '',
            compId: '',
            items: [],
            fields: [
                { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
                    formatter: value => {
                        return this.$moment(value).format("YY-MM-DD @ HH:mm")
                    }
                },                  
                { key: 'sealNo', label: this.$t('seal.sealNo'), sortable: true, },  
                { key: 'agent', label: this.$t('release.agent'), sortable: true, },    
                                                  
                { key: 'act', label: this.$t('matchTx.actions'), variant: 'danger'},

            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],    
		}
	},

	mounted(){
        this.compId= localStorage.getItem("compId")
        agentList((res)=>{
            this.agentList = res
        })
        sealNoListAll(this.compId , (res)=>{
            this.items = res
            this.totalRows = res.length
        })
	},
	methods:{
        async saveOne(){
            let payload = {
                compId : this.compId,
                sealNo : this.sealNoStart,
                agent : this.agent,           
                active: true,
                timestamp: Date.parse(new Date())
            }                
            await saveWithId('Seal', this.sealNoStart, payload)
            await this.refresh()
            this.resetSealInputForm()
            this.$toasted.show(this.$t('reuse.successSave'), {theme: 'bubble', position: 'bottom-center', type: 'success' , duration: 5000});  
        },
        async handleSave(){
            let msg = 'completed'
            if (this.sealNoStart ==='') {
                msg = 'seal.addSealNoStart'
            } else if (this.agent ===''){
                msg = 'seal.selAgent'
            }  
            if (msg === 'completed'){
                if (this.sealNoEnd === '') {
                    await this.saveOne()                
                } else {
                    let ls = this.sealNoStart.length
                    let le = this.sealNoEnd.length
                    if (ls != le) {
                        this.$toasted.show(this.$t('seal.notEqual'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});    
                    } else {
                        let startNo = parseInt(this.sealNoStart.slice((ls-4) , ls))
                        let endNo =  parseInt(this.sealNoEnd.slice((le-4) , le))
                        console.log(`start no => ${startNo} , end no => ${endNo}`)
                        
                        let qty = endNo - startNo
                        if (qty <= 0 ) {
                            await this.saveOne()
                        } else {
                            let frontString = this.sealNoStart.slice(0 ,(ls-4)).toString()
                            console.log(`frontStaring => ${frontString}`)
                            for (let i = startNo; i <= endNo; i++) {
                                let i1 = (i /10000).toFixed(4)
                                let iString = i1.toString().slice(2,6)
                                let sealNo = frontString + iString
                                console.log(`seal no => ${sealNo}`)
                                let payload = {
                                    compId : this.compId,
                                    sealNo : sealNo ,
                                    agent : this.agent,           
                                    active: true,
                                    timestamp: Date.parse(new Date())
                                }                                  
                                await saveWithId('Seal', sealNo, payload)
                            }
                            await this.refresh()
                            this.resetSealInputForm()
                        }
                    }
                }                        
            }else{
                this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
            }
        },
        actDelete(item) {
            this.$bvModal.msgBoxConfirm(this.$t('container.confirmDeleteMsg'), {
            title: this.$t('matchTx.plsConfirm'),
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: this.$t('matchTx.yes'),
            cancelTitle: this.$t('matchTx.no'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
            })
            .then(value => {
                if (value) {
                    deleteItem( "Seal", item.sealNo)
                    this.$toasted.show( this.$t('container.deleted'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
                    this.refresh()
                } else {
                return
                }
            })
            .catch(error => {
                this.$toasted.show( `Action-Delete: ${error}`, {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});  
            })
        },    
        async refresh(){
            await sealNoListAll(this.compId , (res)=>{
                this.items = res
                this.totalRows = res.length
            })
        },
        resetSealInputForm(){
            this.sealNoStart = ''
            this.sealNoEnd= ''       
            this.agent= ''
        },
    }
}
</script>

