<template>
  <div>
    <Breadcrumbs main="shipReport.report" title="shipReport.header" />
    <b-container fluid>
      <!-- <h3 class="mb-1 mt-4 f-w-600">{{ $t('matchTx.header') }}</h3> -->
      <div class="row">
        <div class="col-sm-3 mx-3">
          <label class="f-w-600" style="font-size:16px;">{{ $t("bill.startDate") }}</label>
          <div class="form-group">
              <datepicker input-class="datepicker-here form-control digits" :format="format" v-model="startDate" ></datepicker>
          </div>  
        </div>  
        <div class="col-sm-3 mx-3">
          <label class="f-w-600" style="font-size:16px;">{{ $t("bill.endDate") }}</label>
          <div class="form-group">
              <datepicker input-class="datepicker-here form-control digits"  :format="format" v-model="endDate" ></datepicker>
          </div>                     
        </div> 
        <div class="col-sm-2 ml-3 mt-4">
          <b-button class="my-2 f-w-600" style="font-size:18px;" variant="outline-primary" block @click="runShipReport">
            {{ $t('bill.makeReport') }}
          </b-button>      
        </div> 
        <div class="col-sm-3 ml-3 mt-4">
          <b-button class="my-2 f-w-600" style="font-size:18px;" variant="outline-primary" block @click="exportExcel">
            {{ $t('bill.export') }}
          </b-button>      
        </div> 
      </div>  
      <div class="row">
        <div class="col-sm-3 mx-3">
          <h4> Total :</h4>
          <span class="text-primary f-w-600" style="font-size:18px;">{{ totalRows }}</span>
        </div> 
        <div class="col-sm-3 mx-3">
          <h4> Approved :</h4>
          <span class="text-success f-w-600" style="font-size:18px;">{{ approvedQty }}</span>
        </div>
        <div class="col-sm-3 mx-3">
          <h4> Reject :</h4>
          <span class="text-danger f-w-600" style="font-size:18px;">{{ rejectQty }}</span>
        </div> 
      </div>  
      <div>
      <h4 class="mb-1 mt-4 f-w-600">{{ $t('shipReport.report') }}</h4>
      <b-table
        sticky-header
        head-variant="light"
        table-variant="info"
        show-empty
        sort-icon-left
        stacked="md"      
        :items="items"
        :fields="fields"
        v-model="visibleRows"
        :current-page="currentPage"
        :per-page="perPage"
      >
        <template #empty>
            <h5>{{ $t('reuse.noRecord') }}</h5>
        </template>
      </b-table>
      <b-modal id="contModal" :title="modalTitle" ok-only @hide="resetInfoModal">
          <div class="card me-5" style=" width:15rem">
              <img  class="card-img-top" :src="imageUriModal" alt="Image Preview - Ship Report"/>
          </div>
        </b-modal>
      <b-row>
        <div class="row col-sm-4 ">
          <b-col class="my-1 ml-3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              class="my-0"
            ></b-pagination>
          </b-col>   
          <b-col sm="3" md="6" class="my-1">
            <b-form-group
              :label= "$t('require.perPage')"
              label-for="per-page-select"
              label-cols-sm="8"
              label-cols-md="6"
              label-cols-lg="4"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </div>
      </b-row>
    </div>

  </b-container>
  </div>
</template>
<script>
import { shipReviewByAgent, agentAtCompId } from "../../firebase"
import Datepicker from "vuejs-datepicker";
import * as XLSX from 'xlsx'
  export default {
    data() {
      return {
        compId : '',
        agent : '',
        format: "yyyy-MM-dd",
        startDate : '',
        endDate : '',
        totalQty : 0,
        approvedQty: 0,
        rejectQty: 0,
        items: [],
        fields: [
          { key: 'mTxId', label: this.$t('matchTx.mTxId'), sortable: true, },
          { key: 'isApprove', label: this.$t('shipReport.result'), sortable: true, 
            formatter: value => {
              return this.$t(value)
          }},
          { key: 'agent', label: this.$t('matchTx.agent'), sortable: true, },            
          { key: 'bkgId', label: this.$t('bill.bkgId'), sortable: true, },           
          { key: 'contImage', label: this.$t('matchTx.contNo'), variant: 'info'},            
          { key: 'contType', label: this.$t('matchTx.contType'), sortable: true, },
          { key: 'contSize', label: this.$t('matchTx.contSize'), sortable: true, },
          { key: 'contGrade', label: this.$t('matchTx.contGrade'), sortable: true, },
          { key: 'detDate', label: this.$t('matchTx.detDate'), sortable: true, },          
          { key: 'jobMethCat', label: this.$t('shipReport.jobCat'), sortable: true, },            
          { key: 'compNameLocalRels', label: this.$t('matchTx.compNameLocalRels'), sortable: true, },                
          { key: 'distance', label: this.$t('matchTx.distance'), sortable: true, },
          { key: 'compNameLocalReqr', label: this.$t('matchTx.compNameLocalReqr'), sortable: true, },   
          
          { key: 'timestamp', label: this.$t('shipApproval.date'), sortable: true, 
            formatter: value => {
              return this.$moment(value).format("YY-MM-DD @ HH:mm")
          }},                       
          // { key: 'amount', label: this.$t('bill.amount'), sortable: true, variant: 'warning'}, 
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        visibleRows: [],
        imageUriModal: '',
        modalTitle : ''
      }
    },
    components: {
      Datepicker
    },

    mounted() {
      this.compId= localStorage.getItem("compId")
      agentAtCompId(this.compId , (res)=>{
        this.agent = res
      })
    },
    methods: {
      openModalImage(item, index, button) {
            this.imageUriModal = item.imageUri
            this.modalTitle = item.contNo
            this.$root.$emit('bv::show::modal', "contModal" , button)
        },
      resetInfoModal() {
        this.modalTitle = ''
        this.imageUriModal = ''
      },

      async runShipReport(){
        this.items =[]
        let msg = 'completed'
        if (this.startDate ==='') {
            msg = 'bill.addStartDate'
        } else if (this.endDate ===''){
            msg = 'bill.addEndDate'
        }  
        if (msg === 'completed'){       
          let start = this.$moment(this.startDate).format("YYYY-MM-DD")
          let startAt =   Date.parse(start + ' ' + '00:00:01')
          let end = this.$moment(this.endDate).format("YYYY-MM-DD")
          let endAt =   Date.parse(end + ' ' + '00:00:01')
          await shipReviewByAgent( this.agent, startAt , endAt, async(res)=>{
            this.items = res
            this.totalRows = res.length
            const approvedItems = res.filter( (item)=>{
              if (item.isApprove) {
                return item.mTxId
              }  
            })
            this.approvedQty = approvedItems.length
            this.rejectQty = this.totalRows - this.approvedQty
            // console.log("[shipReviewByAgent] res =>"+ JSON.stringify(res, null , 2)) 
          })         
          }else{
              this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
          }
      },

      exportExcel() {
        if (this.items.length == 0) {
          this.$toasted.show(this.$t('bill.noData'), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000}); 
        } else {
          const dataWS = XLSX.utils.json_to_sheet(this.items)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, dataWS)
          XLSX.writeFile(wb,'shipReport.xlsx')
        }
      },
    }
  }
</script>