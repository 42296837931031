<template>
 <div class="page-wrapper">
    <div class="container-fluid p-0">
        <div class="authentication-main">       
        <div class="row">
            <div class="col-md-12">
                <div class="auth-innerright">
                    <div class="authentication-box">
                        <div class="text-center"><img alt="Change Password" src="../assets/images/changePassword.png"></div>
                        <div class="card mt-4">
                            <div class="card-body">
                                <div >
                                    <h4 class="text-center">{{ $t('forgotPassword.newPasswordReqs') }}</h4>
                                    <h6 class="text-left text-info">{{ $t('forgotPassword.newPasswordMsg1') }} </h6>
                                    <h6 class="text-left text-warning ">{{ $t('forgotPassword.newPasswordMsg2') }} </h6>
                                </div>
                                <form class="theme-form">
                                <div class="form-group">
                                    <label class="col-form-label text-primary f-w-600 pt-0">{{ $t('forgotPassword.yourEmail') }}</label>
                                    <input v-model="email" class="form-control" type="email" :placeholder="$t('forgotPassword.enterEmailAddress')">
                                </div>
                                <div class="form-group form-row m-3" style="justify-content: space-between;">
                                    <div class="row" >
                                    <button class="btn btn-danger btn-block" style="font-size: 18px;" type="button" @click="cancel">{{ $t('forgotPassword.cancel') }}</button>
                                    </div>
                                    <div class="row">     
                                    <button class="btn btn-primary btn-block" style="font-size: 18px;" type="button" @click="sendPwdResetEmail">{{ $t('forgotPassword.sendPwdResetEmail') }}</button>
                                    </div>                                      
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
 </div>
</template>
<script>
import { passwordResetEmail} from "../firebase"
export default {
    data() {
        return{ 
        email: '',        
        password: '',
        }
    },
    methods: {
        cancel() {
            this.$router.push('/auth/login');   
        },
        async sendPwdResetEmail() {
            let msg = 'completed'
            let pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
            if (this.email ===''){
                msg = this.$t('loginPage.enterEmailMsg')
            } else if (!pattern.test(this.email)){
                msg = this.$t('loginPage.emailFormatMsg')
            }  
            if (msg === 'completed'){
                await passwordResetEmail( this.email, (res)=>{
                    if (res === "success") {
                        this.$router.push('/auth/login');
                    this.$toasted.show(this.$t('forgotPassword.successResetEmailSent'), {theme: 'bubble', type: 'success' , position: 'bottom-center', duration: 5000});     
                    } else {
                        this.$toasted.show(this.$t(res), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
                    }
                })
            } else {
                this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
            }    
        },
    }
}
</script>