<template>
    <div>
        <Breadcrumbs main="manual.info" title="manual.userManual" />
        <div class="container-fluid">
            <div class=" col-sm-12">
                <div class="card bg-primary">
                    <div class="card-body">
                        <div class="media-body">
                            <div class="row mb-5">
                                <img src="../../assets/images/conex-logo.png" alt="">  
                                <h2 class="mt-5">{{ $t('manual.title') }}</h2>                        
                            </div>
                            <p class="mt-5" style="font-size: 20px;">{{ $t('manual.propose') }}</p> <br/>
                            <p class="mt-2 mb-3" style="font-size: 20px;">{{ $t('manual.commonSense') }}</p>
                            <div class="row">
                            <div class="col-sm-6">
                                <div class="card bg-light">
                                    <div class="card-body">
                                        <div class="media-body">
                                            <img src="../../assets/images/flowBasic.png"  >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 mt-5">
                                <span style="font-size: 20px;">{{ $t('manual.googleMapInfo') }}</span>
                                <div class=" mt-3">
                                    <span class="text-warning ml-3 mt-4" style="font-size: 25px; text-decoration: underline; cursor: pointer" @click="openManual_th"> {{ $t('manual.userManual_th') }} </span>
                                </div>  
                                <div class=" mt-3" >
                                    <span class="text-light ml-3 mt-4" style="font-size: 20px; text-decoration: underline; cursor: pointer" @click="openManual_en">{{ $t('manual.userManual_en') }}</span>   
                                </div>                  
                            </div>   
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
	data(){
		return{
            manualUrl_th : "https://firebasestorage.googleapis.com/v0/b/conex-a535b.appspot.com/o/doc%2FCONEXusermanual_th.pdf?alt=media&token=0eb6e580-3b59-461a-bf54-324ad89cb5df",
            manualUrl_en : "https://firebasestorage.googleapis.com/v0/b/conex-a535b.appspot.com/o/doc%2FCONEXusermanual_en.pdf?alt=media&token=d3334a84-7efb-4ffe-bd9d-1f3aed56ca9d"
		}
	},
    methods:{
        openManual_th() {
            window.open(this.manualUrl_th)
        },
        openManual_en() {
            window.open(this.manualUrl_en)
        },
    }
}
</script>
