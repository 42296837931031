// import {XLSX} from 'xlsx' // bug
import * as XLSX from 'xlsx'
export const frequencies = arr =>
arr.reduce((a, v) => {
  a[v] = (a[v] ?? 0) + 1;
  let b = a[v]
  return a;
}, {});

export const getMax = arr =>
arr.reduce((prev , curr)=>{
    return curr > prev ? curr : prev;
})

export const getMin = arr =>
arr.reduce((prev , curr)=>{
    return curr < prev ? curr : prev;
})


export const getRangeXaxis = (min, max)=>{
    let arrX = []
    let minM = parseInt(min.slice(4,6))
    let maxM = parseInt(max.slice(4,6))
    if ((max-min) <= 12 ) {
        for (let i = minM; i <= maxM; i++){
            let MM = i <= 9 ? `0${i}` : `${i}`
            arrX.push(`${min.slice(0,4)}/${MM}`);    
        }  
    } else {  //  across year
        for (let i = minM; i <= 12; i++) {
            let MM = i <= 9 ? `0${i}` : `${i}`
           arrX.push(`${min.slice(0,4)}/${MM}`);
        }
        for (let i = 1; i <= maxM; i++) {
            let MM = i <= 9 ? `0${i}` : `${i}`
           arrX.push(`${max.slice(0,4)}/${MM}`);
        }
    }
    return arrX
}
export const getRangeXyyyyMM = (min, max)=>{
    let arrX = []
    let minM = parseInt(min.slice(4,6))
    let maxM = parseInt(max.slice(4,6))
    if ((max-min) <= 12 ) {
        for (let i = minM; i <= maxM; i++){
            let MM = i <= 9 ? `0${i}` : `${i}`
            arrX.push(`${min.slice(0,4)}${MM}`);    
        }  
    } else {  //  across year
        for (let i = minM; i <= 12; i++) {
            let MM = i <= 9 ? `0${i}` : `${i}`
           arrX.push(`${min.slice(0,4)}${MM}`);
        }
        for (let i = 1; i <= maxM; i++) {
            let MM = i <= 9 ? `0${i}` : `${i}`
           arrX.push(`${max.slice(0,4)}${MM}`);
        }
    }
    return arrX
}
export const exportXlsx = (items , name)=>{
    const dataWS = XLSX.utils.json_to_sheet(items)
const wb = XLSX.utils.book_new()
XLSX.utils.book_append_sheet(wb, dataWS)
XLSX.writeFile(wb, `${name}.xlsx`)
}


    