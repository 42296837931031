<template>
    <div>
        <Breadcrumbs main="manual.info" title="TC.header"/>
        <!-- Container-fluid starts-->
        <div class="container-fluid">
         <div class="row">
            <div class="col-sm-12">
                <div class="card-body">
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.1') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.1_1') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.2') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.2_1') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.3') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.3_1') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.3_2') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.3_3') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.3_4') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.3_5') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.4') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.4_1') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.5') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.5_1') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.5_2') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.5_3') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.5_4') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.6') }}</span><br/>
                    <span style="font-size: 18px; color:blue">{{ $t('TC.6_1') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.6_1_1') }}</span><br/>
                    <span style="font-size: 18px; color:blue">{{ $t('TC.6_2') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.6_2_1') }}</span><br/>
                    <span style="font-size: 18px; color:blue">{{ $t('TC.6_3') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.6_3_1') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.7') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.7_1') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.7_2') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.8') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.8_1') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.8_2') }}</span><br/>
                    <span style="font-size: 18px; color:red">{{ $t('TC.8_2Ag') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.9') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.9_1') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.10') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.10_1') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.10_2') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.11') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.11_1') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.11_2') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.11_3') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.12') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.12_1') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.13') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.13_1') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.14') }}</span><br/>
                    <span style="font-size: 18px; color:blue">{{ $t('TC.14_1') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.14_1_1') }}</span><br/>
                    <span style="font-size: 18px; color:blue">{{ $t('TC.14_2') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.14_2_1') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.14_2_2') }}</span><br/>
                    <span style="font-size: 18px; color:blue">{{ $t('TC.14_3') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.14_3_1') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.15') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.15_1') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.16') }}</span><br/>
                    <span style="font-size: 18px;">{{ $t('TC.16_1') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.17') }}</span><br/><br/>
                    <span style="font-size: 18px; font-weight: bold;">{{ $t('TC.18') }}</span>
                </div>
            </div>
         </div>
        </div>
    <!-- Container-fluid Ends-->
</div>
</template>

<script>
export default {

    data(){
        return{

            email: "",
            supported_form:{
                checkbox:false,
                radio:null,
                selected: null,
                file:null
            },
            supported_form_radio_options: [
              { text: 'Toggle this custom radio', value: 'first' },
              { text: 'Or toggle this other custom radio', value: 'second' }
            ],
            supported_form_select_options: [
              { value: null, text:'Open this select menu' },
              { value: '1', text:'One' },
              { value: '2', text:'Two' },
              { value: '3', text:'Three' },
            ],
            c_form:{
                first_name:'',
                last_name:'',
                username:'',
                city:'',
                state:'',
                zip:''
            },
            c_form_result:{
                first_name:null,
                last_name:null,
                username:null,
                city:null,
                state:null,
                zip:null
            },
            tooltip_form:{
                first_name:'',
                last_name:'',
                username:'',
                city:'',
                state:'',
                zip:''
            },
            tooltip_form_result:{
                first_name:null,
                last_name:null,
                username:null,
                city:null,
                state:null,
                zip:null
            },
            
        }
    },
    computed:{
        supported_checkbox_state(){
            return this.supported_form.checkbox
        },
        supported_radio_state(){
            return Boolean(this.supported_form.radio)
        },
        supported_select_state(){
            if(this.supported_form.selected == null)
            {
                return false;
            }
            else
            {
                return true;
            }
        },
        supported_file_state(){
            if(this.supported_form.file === null)
            {
              return false
            }
            else
            {
              return true
            }
        },
    },
    methods:{
        onCustomStyleSubmit(evt){            
            evt.preventDefault()
            if(this.c_form.first_name != '')
            {
                console.log("custom stylr", this.c_form.first_name);
                this.c_form_result.first_name = true
            }
            else
            {
                this.c_form_result.first_name = false
            }

            if(this.c_form.last_name != '')
            {
                this.c_form_result.last_name = true
            }
            else
            {
                this.c_form_result.last_name = false
            }

            if(this.c_form.username != '')
            {
                this.c_form_result.username = true
            }
            else
            {
                this.c_form_result.username = false
            }

            if(this.c_form.city != '')
            {
                this.c_form_result.city = true
            }
            else
            {
                this.c_form_result.city = false
            }

            if(this.c_form.state != '')
            {
                this.c_form_result.state = true
            }
            else
            {
                this.c_form_result.state = false
            }

            if(this.c_form.zip != '')
            {
                this.c_form_result.zip = true
            }
            else
            {
                this.c_form_result.zip = false
            }
        },

        onTooltipSubmit(evt){
            evt.preventDefault()
            if(this.tooltip_form.first_name != '')
            {
                this.tooltip_form_result.first_name = true
            }
            else
            {
                this.tooltip_form_result.first_name = false
            }

            if(this.tooltip_form.last_name != '')
            {
                this.tooltip_form_result.last_name = true
            }
            else
            {
                this.tooltip_form_result.last_name = false
            }

            if(this.tooltip_form.username != '')
            {
                this.tooltip_form_result.username = true
            }
            else
            {
                this.tooltip_form_result.username = false
            }

            if(this.tooltip_form.city != '')
            {
                this.tooltip_form_result.city = true
            }
            else
            {
                this.tooltip_form_result.city = false
            }

            if(this.tooltip_form.state != '')
            {
                this.tooltip_form_result.state = true
            }
            else
            {
                this.tooltip_form_result.state = false
            }

            if(this.tooltip_form.zip != '')
            {
                this.tooltip_form_result.zip = true
            }
            else
            {
                this.tooltip_form_result.zip = false
            }
        }
    }
}
</script>
