<template>
	<div>
		<Breadcrumbs main="agent.resource" title="agent.header"/>
		<!-- Container-fluid starts-->
		<div class="container-fluid search-page">
			<div class="row">
                <div class="col-sm-6">
                    <!-- <form class="theme-form">
                        <div class="form-group">
                            <label for="agentNameLocal" class="col-form-label pt-0">{{ $t("agent.agentNameLocal") }}</label>                                                 
                            <input  v-model="agentNameLocal" class="form-control" type="text" id="agentNameLocal" :placeholder="$t('agent.agentNameLocalPh')" >
                        </div>      
                        <div class="form-group">
                            <label for="agentNameEng" class="col-form-label pt-0">{{ $t("agent.agentNameEng") }}</label>    
                            <input v-model="agentNameEng" class="form-control" type="text" id="agentNameEng" :placeholder="$t('agent.agentNameEngPh')" >
                        </div>
                        <div class="form-group">
                            <label for="contactName" class="col-form-label pt-0">{{ $t("agent.contactName") }}</label>
                            <input v-model="contactName" class="form-control" type="text" id="contactName" :placeholder="$t('agent.contactNamePh')" >
                        </div>
                        <div class="form-group">
                            <label for="contactEmail" class="col-form-label pt-0">{{ $t("agent.contactEmail") }}</label>
                            <input v-model="contactEmail" class="form-control" type="email" id="contactEmail" :placeholder="$t('agent.contactEmailPh')" >
                        </div>
                        <div class="form-group">
                             <label for="contactPhone" class="col-form-label pt-0">{{ $t("agent.contactPhone") }}</label>
                            <input v-model="contactPhone" class="form-control" type="text" id="contactPhone" :placeholder="$t('agent.contactPhonePh')">
                        </div>
                        <div class="form-group mt-3 mb-0">
                            <button class="btn btn-primary btn-block" type="button" @click="handleSave">{{ $t('agent.save') }}</button>
                        </div>
                    </form> -->
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->
    </div>
</template>
<script>
import { saveWithId} from "../../firebase"

export default {
	data(){
		return{
            agentId : '',
            agentNameLocal: '',
            agentNameEng: '',
            contactName: '',            
            contactEmail: '',
            contactPhone: ''
		}
	},

	methods:{
        handleSave(){
            let msg = 'completed'
            let pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
            if (this.agentNameLocal ==='') {
                msg = 'agent.addAgentNameLocal'
            } else if (this.agentNameEng ===''){
                msg = 'agent.addAgentNameEng'
            } else if (this.contactName ===''){
                msg = 'agent.addContactName'
            } else if (this.contactEmail ===''){
                msg = 'agent.addContactEmail'
            } else if (!pattern.test(this.contactEmail)){
                msg = 'agent.contactEmailFormat'
            } else if (this.contactPhone ===''){
                msg = 'agent.addContactPhone'
            } else if (isNaN(parseInt(this.contactPhone))){
                msg = 'agent.contactPhoneNumber'
            } else if (this.contactPhone.length < 9 ){
                msg = 'agent.contactPhone9Digits'
            }  
            if (msg === 'completed'){
                // console.log ('msg=>'+msg)
                let agentId = this.agentNameEng.substring(0,1).toUpperCase()+`${Math.floor(Math.random() * (90000 - 10000 + 1) + 10000)}`
                let payload = {
                    agentId : agentId,
                    agentNameLocal: this.agentNameLocal,
                    agentNameEng: this.agentNameEng,
                    contactName: this.contactName,         
                    contactEmail: this.contactEmail,
                    contactPhone: this.contactPhone,
                }
               // console.log ('payload=>'+JSON.stringify(payload , null, 2))
                saveWithId('Agent', agentId, payload)
                this.$toasted.show(this.$t('reuse.successSave'), {theme: 'bubble', type: 'success' , position: 'bottom-center', duration: 10000});    
                this.$router.push('/');                                                
            }else{
                this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
            }
        }
    }
}
</script>
