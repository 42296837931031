// import products from '../../data/products'

// let user =localStorage.getItem("user") || 'userDefualt';
// let userCat =localStorage.getItem("userCat") || 'userCatDefualt';
const state = {
	user: "user",
	userCat: "userCat",
}

// getters
const getters = {
	// getUser(state){
	// 	return state.user
	// },
	// getUserCat(state){
	// 	return state.userCat
	// },
}

// mutations
const mutations = {

}

// actions
const actions = {

}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}