
<template>

    <div class="locale-switcher">
      <select v-model="$i18n.locale" class="form-select form-select-lg">
        <option value="th">TH</option>             
        <option value="en">EN</option>        
      </select>
    </div>

  </template>
  <script>
  export default {
    name: 'LocalSwitcher',
    }
  </script>