<template>
   <!-- page-wrapper Start-->
    <div class="page-wrapper">
        <div class="container-fluid">
            <!-- sign up page start-->
            <div class="authentication-main">
                <div class="row">
                    <div class="col-sm-12 p-0">
                        <div class="auth-innerright">
                            <div class="authentication-box">
                                <div class="text-center"><img src="../assets/images/conex-logo.png" alt=""></div>
                                <div class="card mt-4 p-4">
                                    <h4 class="text-center">{{ $t('register.newUser') }}</h4>
                                    <h6 class="text-center text-info ">{{ $t('register.nameAndPassword') }}</h6>
                                    <form class="theme-form">

                                        <div class="form-row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="name" class="col-form-label text-primary f-w-600">{{ $t('register.name') }}</label>
                                                    <input class="form-control" id="name" v-model="name" type="text" :placeholder="$t('register.namePh')">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="phone" class="col-form-label text-primary f-w-600">{{ $t('register.phone') }}</label>
                                                    <input class="form-control" id="phone" v-model="phone" type="text" :placeholder="$t('register.phonePh') ">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="email" class="col-form-label text-primary f-w-600">{{ $t('register.email') }}</label>
                                            <input class="form-control" id="email" v-model="email" type="email" :placeholder="$t('register.emailPh')">
                                        </div>
                                        <div class="form-group">
                                            <label for="password" class="col-form-label text-primary f-w-600">{{ $t('register.password') }}</label>
                                            <input autocomplete="" class="form-control" id="password" v-model="password" type="password" :placeholder="$t('register.passwordPh')">
                                        </div>
                                        <div class="form-group">
                                            <h4 class="f-w-600">{{ $t('register.workingPlace') }}</h4>
                                            <div class="form-row">
                                                <div class="col-sm-6">
                                                    <label for="userCat" class="col-form-label text-primary pt-0 f-w-600">{{ $t('register.userCat') }}</label>
                                                    <!-- <select class="form-control mb-1" id="userCat" ref="userCat">
                                                        <option value="logist">{{ $t('register.logist') }}</option>
                                                        <option value="agent">{{ $t('register.agent') }}</option>
                                                        <option value="cert">{{ $t('register.cert') }}</option>
                                                        <option value="driver">{{ $t('register.driver') }}</option>
                                                        <option value="conex">{{ $t('register.conex') }}</option>                                                        
                                                    </select> -->
                                                    <b-form-select :value="userCat" @input="getCompNameList" class="mb-3">
                                                        <b-form-select-option value="logist">{{ $t('register.logist') }}</b-form-select-option>
                                                        <b-form-select-option value="agent">{{ $t('register.agent') }}</b-form-select-option>
                                                        <b-form-select-option value="cert" disabled>{{ $t('register.cert') }}</b-form-select-option>
                                                        <b-form-select-option value="driver" disabled>{{ $t('register.driver') }}</b-form-select-option>
                                                        <b-form-select-option value="conex" disabled>{{ $t('register.conex') }}</b-form-select-option>
                                                    </b-form-select>
                                                </div>
                                                <div class="col-sm-6 text-primary">
                                                    <label for="compId" class="col-form-label text-primary pt-0 f-w-600">{{ $t("register.compId") }}</label>  
                                                    <v-select label="agent" v-model="selectedCompName" :options="compNameList">

                                                    </v-select>
                                                </div>
                                            </div>
                                        </div>  
                                        <div class="form-row">
                                            <div class="col-sm-4">
                                                <button @click="signUp" class="btn btn-primary" type="button">{{ $t('register.signUp') }}</button>
                                            </div>
                                            <div class="col-sm-8">
                                                <div class="text-left mt-2 m-l-20">{{ $t('register.wasUser') }}  <a class="btn-link text-capitalize" href="/auth/login">Login</a></div>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- sign up page ends-->
        </div>
    </div>
    <!-- page-wrapper Ends-->
</template>

<script>
import { createUserWithEmailAndPassword } from 'firebase/auth'
import {authUser , compNameList, compIdByCompName ,saveWithId} from "../firebase"
export default {
    name: 'register',

    data() {
        return{
            compNameList : [],
            userId: '',
            name : '',
            phone : '',
            userCat : '',
            selectedCompName : '',
            email: '',
            password: '',
            compId:''
        }
    },
    mounted(){
        // compNameList((res)=>{
        //     this.compNameList = res
        // })
    },
    methods: {
        async getCompNameList(value){
            this.userCat = value 
            let opt = value === 'agent' ? '==' : '!='
            await compNameList(  opt ,(res)=>{
                this.compNameList = res
            })            
        },
        signUp() {
            let msg = 'completed'
            let pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
            if (this.name === ''){
                msg = "Please enter user name."
            } else if (this.phone ===''){
                msg = "Please enter phone no."
            } else if (this.email ===''){
                msg = "Please enter your email address."
            } else if (!pattern.test(this.email)){
                msg = "Please verify your email format."
            } else if (this.password ===''){
                msg = "Please enter your password."
            } else if (this.password.length < 8){
                msg = "Password must be 8 digits up."
            } else if (this.userCat===''){
                msg = "Please select user's role or category."
            } else if (this.selectedCompName ===''){
                msg = "Please select user's company."
            }  
            if (msg === 'completed'){
                createUserWithEmailAndPassword(authUser, this.email, this.password)
                .then( async(userCredential) => {
                    if (userCredential) {
                        await compIdByCompName(this.selectedCompName, async(compId)=>{
                            let userId = `${Math.floor(Math.random() * (9000000 - 1000000 + 1) + 1000000)}`
                            let payload = {
                                uid: userCredential.user.uid,
                                userId : userId,
                                name: this.name,
                                phone: this.phone,
                                userCat: this.userCat,
                                compId: compId,
                                email: this.email,
                                active: true,
                            }
                            await saveWithId('Users', userId, payload).then(()=>{
                                this.$toasted.show('Success register to email:' + this.email, {theme: 'bubble', type: 'success' , position: 'bottom-center', duration: 5000});    
                                localStorage.setItem('user', this.email)
                                localStorage.setItem('userId', userId)
                                localStorage.setItem('userCat', this.userCat)
                                localStorage.setItem('compId', compId)
                                // this.$router.push('/'); 
                                switch (this.userCat) {
                                    case 'agent':
                                        setTimeout(function () {
                                            window.location.href='/dashboard/shipApproval'  
                                        },500)                                         
                                        break;
                                    case 'conex':
                                        setTimeout(function () {
                                            window.location.href='/dashboard/matchingAdmin'   
                                        },500)                                         
                                        break;
                                    case 'cert':
                                        setTimeout(function () {
                                            window.location.href='/'  
                                        },500)                                         
                                        break;
                                    case 'driver':
                                        setTimeout(function () {
                                            window.location.href='/'  
                                        },500)                                         
                                        break;
                                    case 'logist':
                                        setTimeout(function () {
                                            window.location.href='/dashboard/matching'  
                                        },500)                                         
                                        break;    
                                    default:
                                        break;
                                }
                            })
                        })
                    }
                })
                .catch((error) => {
                    this.$toasted.show('Error...' + error.message, {theme: 'bubble',   position: 'bottom-center',   type: 'error', duration: 5000});
                });                                              
            }else{
                this.$toasted.show(this.$t(msg), {theme: 'bubble', type: 'error' , position: 'bottom-center', duration: 5000});     
            }
        },
        doLogin() {
            this.$router.push('/auth/login');
        },
    }
}
</script>
